import GradButton from "../../../components/Button/GradButton/GradButton";
import Navbar from "../../../components/HomeNavbar";
import Circle from "../../../assets/Circle.svg";
import "./LandingPage.css"

function LandingPage() {
    return(
        <div className="lp-container">
            
            <div class="hero-section">
                <div className="hero">
                    <h4>End to End</h4>
                    <div className="title">
                        <h1 className="digital">Digital</h1>
                        <h1 className="transformation">Transformation</h1>
                    </div>
                    <p>Empower your transformation with our centralized data powerhouse. Streamline operations, foster collaboration, and ignite innovation seamlessly.</p>
                    {/* <GradButton className="grd-btn" text="Learn More"/> */}
                </div>
                <div className="hero-img">
                     <img src={Circle} alt="Circle"/>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
