import { Avatar, AvatarGroup, Box, Chip, Link, MenuItem, Tooltip } from "@mui/material";
import { stringAvatar } from "../components/StringAvatar";
import { LinearProgressWithLabel } from "../../../../components/widgets/LinearProgressWithLabel";
import paths from "../../../../api/paths.api";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { convertDateString } from "../../../../utils/convertDateString";
import { categories } from "../utils";

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const columns = (users) => [
    {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
        Edit: () => (
            <></>
        ),
    },
    {
        accessorKey: 'start',
        header: 'Start',
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => (
            <>
                {
                    cell.getValue() ?
                        <div style={{ whiteSpace: 'wrap' }}>
                            {convertDateString(cell.getValue())}
                        </div>
                        : ""
                }
            </>
        ),
        Edit: () => (
            <></>
        ),
    },
    {
        accessorKey: "projectName",
        header: "Project Name",
        size: 500,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
            required: true,
        }),
    },
    {
        accessorKey: "type",
        header: "Project Type",
        size: 100,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
            required: false,
            select: true,
            children: categories.map((category, i) =>
                <MenuItem key={i} value={category}>
                    {category}
                </MenuItem>
            )
            // [

            //     <MenuItem key={1} value={'Software'}>
            //         Software</MenuItem >,
            //     <MenuItem key={2} value={'Engineering'}>
            //         Engineering</MenuItem>,
            //     <MenuItem key={3} value={'PLM'}>
            //         PLM</MenuItem>,
            //     <MenuItem key={4} value={'Website'}>
            //         Website</MenuItem>,
            // ],
            // SelectProps: {
            //     multiple: false,
            //     renderValue: (selected) => (
            //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            //            {selected}
            //         </Box>
            //     )
            // }
        }),
    },
    {
        accessorKey: "projectLeads",
        header: "Lead",
        size: 100,
        Cell: ({ row, cell }) => {
            const members = cell.getValue();
            const visibleMembers = members?.slice(0, 2); // Show only the first 2 members

            return (
                <>
                    {visibleMembers?.map((member, index) => (
                        <Chip key={index} label={member} size="small" />
                    ))}
                    {members?.length > 2 && (
                        <Tooltip title={members.slice(2).join(', ')} arrow>
                            <Chip label={`${members.length - 2}+`} size="small" />
                        </Tooltip>
                    )}
                </>
            );
        },
        muiEditTextFieldProps: ({ value, onChange }) => ({
            children: users.map((user, index) => (
                <MenuItem key={index} value={user}>
                    {user}
                </MenuItem>
            )),
            required: true,
            select: true,
            SelectProps: {
                multiple: true,
                renderValue: (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, index) => (
                            <Chip key={index} label={value} />
                        ))}
                    </Box>
                )
            }
        }),
    },
    {
        accessorKey: "members",
        header: "Members",
        size: 150,
        Cell: ({ row, cell }) => {
            const members = cell.getValue();
            const visibleMembers = members?.slice(0, 2); // Show only the first 2 members

            return (
                <>
                    {visibleMembers?.map((member, index) => (
                        <Chip key={index} label={member} size="small" />
                    ))}
                    {members?.length > 2 && (
                        <Tooltip title={members.slice(2).join(', ')} arrow>
                            <Chip label={`${members.length - 2}+`} size="small" />
                        </Tooltip>
                    )}
                </>
            );
        },
        muiEditTextFieldProps: ({ value, onChange }) => ({
            children: users.map((user, index) => (
                <MenuItem key={index} value={user}>
                    {user}
                </MenuItem>
            )),
            select: true,
            SelectProps: {
                multiple: true,
                renderValue: (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, index) => (
                            <Chip key={index} label={value} />
                        ))}
                    </Box>
                )

            }
        }),
    },
    {
        accessorKey: "tasks",
        header: "Tasks",
        size: 160,
        enableEditing: false,
        enableColumnFilter: false,
        Edit: () => (
            <div></div>
        ),
        Cell: ({ row, cell }) => {
            let completed = cell.getValue()?.completed
            let total = cell.getValue()?.total
            const progress = completed / total * 100
            return (
                <LinearProgressWithLabel
                    value={progress || 0}
                    alternateLabel={`${completed ?? 0}/${total ?? 0}`} />
            )
        }
    },

]