import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { AuthContextProvider } from './context/authContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './services/msal';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <App />
          {/* <ReactQueryDevtools initialIsOpen/> */}
        </AuthContextProvider>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);
