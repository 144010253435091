import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { taskListColumns } from "./columns";
import { useParams, useNavigate } from "react-router-dom";
import RowEditableTable from "../../../../../components/Tables/RowEditableTable";
import { useFieldArray, useForm } from "react-hook-form";
import { getAllUsernames } from "../../../../../api/usersApi";
import CommentsDialog from "./CommentsDialog";
import TimesheetsDialog from "./TimesheetsDialog";
// import { EditableTable } from "./TaskTable";
import { useCreateTask, useDeleteTask, useGetTasks, useUpdateTask } from "./hooks";
import { sidebarWidth, validateTask } from "../../utils";
import { minimalMuiTableProps } from '../../../../../styles/muiProps';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../../../../context/authContext";
import { useMsal } from "@azure/msal-react";
import { Skeleton, Typography, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import { archiveTask } from "./api";

import { EditableTable } from '../../../../../components/Tables/EditableTable'


const initialData = {
  task: "",
  task_members: [],
  priority: "",
  targetDate: "",
  hours: 0,
  progress: 0,
  status: "",
  comments: [],
  attachment: "",
  isNew: true
}

export default function TaskList({ selectedProject }) {
  const queryClient = useQueryClient();
  const { accessToken, auth } = useContext(AuthContext);
  const { accounts } = useMsal();
  const username = accounts[0]?.name;
  const currentUser = auth?.role;

  const { isLoading, isError, error, isSuccess, data: user_data } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsernames(accessToken),
  });

  const initialDialogState = {
    comments: false,
    attachment: false,
    timesheet: false
  };

  const [dialogState, setDialogState] = useState(initialDialogState);
  const [selectedTask, setSelectedTask] = useState();
  const [filterType, setFilterType] = useState('');
  const [filteredTasks, setFilteredTasks] = useState(false);

  const { data: tasksData, isLoading: tasksLoading } = useGetTasks({ accessToken });


  const handleButtonClick = (type, row) => {
    setSelectedTask(row.original);
    queryClient.setQueryData(['taskId'], row.id, { staleTime: Infinity });

    if (type === 'comments') {
      setDialogState(prev => ({ ...prev, comments: true }));
    } else if (type === 'attachment') {
      setDialogState(prev => ({ ...prev, attachment: true }));
    } else if (type === 'timesheet') {
      setDialogState(prev => ({ ...prev, timesheet: true }));
    }
  };

  const handleDialogClose = () => {
    setDialogState(initialDialogState);
  };

  const hooks = {
    useCreateItem: (selectedTask) => useCreateTask(selectedTask),
    useGetItems: useGetTasks,
    useUpdateItem: useUpdateTask,
    useDeleteItem: useDeleteTask
  };

  const actions = {
    validateItem: validateTask,
    archiveItem: archiveTask,
  };

  const additionalProps = {
    ...minimalMuiTableProps,
    enableSorting: true,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        backgroundColor: "rgba(0,0,0,0)",
        maxWidth: '80vw'
      }
    }
  };

  const isProjectLead = selectedProject && selectedProject?.projectLeads?.some((lead) => lead === username);
  const isAdmin = currentUser === "admin" || currentUser === "owner";

  useEffect(() => {
    if (!tasksData) return;

    let tasks = tasksData?.filter(task => !task.isArchived) || [];
    const today = dayjs();

    if (filterType === 'dueToday') {
      tasks = tasks.filter(task => dayjs(task.targetDate).isSame(today, 'day'));
    } else if (filterType === 'overdue') {
      tasks = tasks.filter(task => dayjs(task.targetDate).isBefore(today, 'day') && task.progress < 100);
    } else if (filterType === 'highPriority') {
      tasks = tasks.filter(task => task.priority === 'High');
    }
    console.log('tasks', tasks);


    setFilteredTasks(tasks);
    console.log('filtered tasks', filteredTasks);
  }, [filterType, tasksData]);

  const handleToggleChange = (event, newFilterType) => {
    const today = dayjs();
    let newTasks;

    if (filterType === 'dueToday') {
      newTasks = tasksData.filter(task => dayjs(task.targetDate).isSame(today, 'day'));
    } else if (filterType === 'overdue') {
      newTasks = tasksData.filter(task => dayjs(task.targetDate).isBefore(today, 'day') && task.progress < 100);
    } else if (filterType === 'highPriority') {
      newTasks = tasksData.filter(task => task.priority === 'High');
    }
    setFilteredTasks(newTasks)
  }

  return (
    <Box sx={{
      width: "-webkit-fill-available", typography: "body1", overflow: "auto", margin: "16px  12px 12px 12px",
      marginLeft: "210px", // Adjust to match the sidebar's width "main sidebar 65px, 200px project list sidebar"
      width: 'calc(100% - 200px)',
    }}>
      {isProjectLead !== undefined ?
        <>
          <Typography variant="h5">{selectedProject.projectName}</Typography>
          <ToggleButtonGroup
            value={filterType}
            exclusive
            onChange={(event, filterType) => { setFilterType(filterType) }}
            aria-label="task filter"
            sx={{ marginBottom: 2 }}
          >
            <ToggleButton value="dueToday" aria-label="due today">
              Due Today
            </ToggleButton>
            <ToggleButton value="overdue" aria-label="overdue">
              Overdue
            </ToggleButton>
            <ToggleButton value="highPriority" aria-label="high priority">
              High Priority
            </ToggleButton>
          </ToggleButtonGroup>
          <EditableTable
            columns={taskListColumns(selectedProject?.members || [], handleButtonClick, username, selectedProject?.projectLeads)}
            name="Task"
            actions={actions}
            hooks={hooks}
            editMode="row"
            enableCreate={true}
            enableEditing={true}
            enableArchive={true}
            additionalProps={additionalProps}
            initialData={initialData}
            data={filteredTasks ? filteredTasks : []}
          />
        </>
        : <>
          <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} height={60} width={450} />
          <Skeleton animation="wave" variant="rectangular" height={"50vh"} />
        </>
      }
      <CommentsDialog open={dialogState.comments} handleClose={handleDialogClose} task={selectedTask} />
      <TimesheetsDialog open={dialogState.timesheet} handleClose={handleDialogClose} task={selectedTask} />
    </Box>
  );
}
