import React, { useContext, useState } from 'react';
import {
    Avatar,
    Box,
    Container,
    Paper,
    TextField,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Typography,
    Dialog,
    DialogContent,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { AuthContext } from '../../../../../../context/authContext';
import { createComment, getComments } from './api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';

const commentsData = [
    { id: 1, user: 'User1', text: 'This is the first comment.' },
    { id: 2, user: 'User2', text: 'Another comment here.' },
    // Add more comments as needed
];

const CommentsDialog = ({ open, handleClose, task }) => {
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState(commentsData);
    const { accessToken } = useContext(AuthContext);
    const { accounts } = useMsal();
    const currentUser = accounts[0]?.name;
    const queryClient = useQueryClient()


    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: comments_data
    } = useQuery({
        queryKey: ['comments', task?.id],
        queryFn: () => getComments(accessToken, task?.id),
        enabled: accessToken !== null && task?.id !== undefined,
    })

    const createCommentMutation = useMutation({
        mutationFn: (comment) => {
            return createComment({ comment, accessToken })
        },
        onSuccess: () => {
            queryClient.invalidateQueries('comments', task?.id)
        }
    })

    const handleAddComment = () => {
        if (newComment.trim() !== '') {
            const newCommentObject = {
                user: currentUser,
                text: newComment.trim(),
            };
            const commentObject = {
                comment: newComment.trim(),
                taskId: task?.id,
            }
            createCommentMutation.mutate(commentObject)
            setComments((prevComments) => [...prevComments, newCommentObject]);
            setNewComment('');
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} padding={2} fullWidth>
            <DialogContent>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Comments
                    </Typography>
                    <List>
                        {
                            comments_data?.length ?
                                comments_data?.map((comment, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>{comment.user.username.charAt(0)}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={comment.user.username}
                                                secondary={comment.comment}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))
                                :
                                <>
                                    Add a comment on the task
                                </>
                        }
                    </List>
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            label="Add a comment"
                            variant="outlined"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddComment}
                            endIcon={<SendIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Add Comment
                        </Button>
                    </Box>
                </Paper>
            </DialogContent >
        </Dialog>
    );
};

export default CommentsDialog;
