import { Card, CardContent, Paper, Typography } from '@mui/material'
import React from 'react'

const HoursSpentCard = () => {
    return (
        <Paper elevation={2} sx={{ width: "400px", height: "328px" }}>

        </Paper>

    )
}

export default HoursSpentCard