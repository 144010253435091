import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

import { StyleContext } from "../../../context/styleContext";
import SideNavHeader from "./SideNavHeader";
import SideNavList from "./SideNavList";
import {
  adminList,
  // SharePointSideNavList,
  // addNewProjectSideNavList,
  // bottomSideNavList,
  ownerList, staffList,
  // vendorList,
} from "./ContentArrays";
import { AuthContext } from "../../../context/authContext";


import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: "none",
  "& .css-1r9jet7": {
    minHeight: "50px"
  },
  backgroundColor: "hsla(250, 86%, 97%, 1)",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: "none",
  "& .css-1r9jet7": {
    minHeight: "50px"
  },
  backgroundColor: "#F8F9FAFF",
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) -1px -1px 2px 0px",
  // backgroundColor: "hsla(250, 86%, 97%, 1)",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "& .MuiSvgIcon-root": {
    color: "#242964"
  },
  // "& .MuiDrawer-docked .MuiDrawer-paper": {
  // }
  // borderRight: "1px solid rgba(0, 0, 0, 0.12);"
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),

  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function SideNav() {
  const { sideNavOpen } = useContext(StyleContext);
  const { auth } = useContext(AuthContext);
  const currentUser = auth;


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={sideNavOpen} sx={{ top: "50px" }}>
        <SideNavHeader />
        <Divider />
        {/* <SideNavList listContent={SharePointSideNavList} /> */}
        {/* <Divider />
        <SideNavList listContent={addNewProjectSideNavList} /> */}
        <Divider />{

          <SideNavList listContent={staffList} />
        }
        <Divider />
        {
          auth?.role === "admin" || auth?.role === "owner" ?
            <>
              <List sx={{
                padding: 0
              }}>
                <ListItemButton onClick={handleClick} >
                  <ListItemIcon sx={{ ml: 1 }} >
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                  {
                    sideNavOpen ?
                      open ?
                        <ExpandLess />
                        : <ExpandMore />
                      : <></>
                  }
                </ListItemButton>
              </List>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <SideNavList listContent={adminList} />
              </Collapse>
            </>
            :
            <></>
        }
        <Divider />{
          auth?.role === "owner" ?
            <SideNavList listContent={ownerList} />
            :
            <></>
        }
        {/* <SideNavList listContent={vendorList} />
        <Divider /> */}
        {/* <SideNavList listContent={bottomSideNavList} bottom /> */}
      </Drawer>
    </Box>
  );
}
