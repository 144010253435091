import "./software.css";
import Line from "../../../assets/sw-hero-decorator-line.svg";
import Septagon from "../../../assets/septagon.svg"

function SoftwarePage() {
    return (
        <div className="sw-container">
            <div class="hero-section">
                <div className="hero-img">
                    <img src={Septagon} />
                </div>
                <div className="sw-hero">
                    <div className="title-container">
                        <img src={Line} className="gradient-line" />
                        <h1 className="sw-title line1">Transform Your</h1>
                        <h1 className="sw-title line2">Engineeering Processes with</h1>
                        <h1 className="sw-title line3">Customized Software Solutions</h1>
                    </div>
                    <p className="sw-desc">Empower your transformation with our centralized data powerhouse. Streamline operations, foster collaboration, and ignite innovation seamlessly.</p>
                </div>
            </div>
        </div>
    );
}

export default SoftwarePage;