import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import Logo from "../../../images/chinook_blue-logo-only.png"
import { AccountCircle } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { useMsal } from '@azure/msal-react';

const drawerWidth = 210;

const settings = ['Logout'];


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#F3F4F6FF",
    background: "linear-gradient(45deg, #f3f1fe 0%, #d9e7ff 100%)",
    color: "Black",
    boxShadow: "none",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }),
}));


const ResponsiveAppBar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { accounts } = useMsal();

    const { instance } = useMsal();
    const navigate = useNavigate()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigation = () => {
        navigate("/");
    }

    const logoutAndRedirect = () => {
        instance.logoutRedirect()
        navigate("/");
    };

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar variant='dense' sx={{ display: 'flex', justifyContent: "space-between" }}>
                <Box display="flex">
                    {
                        accounts?.length ?
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                // onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton> :
                            <></>
                    }
                    <img src={Logo} alt="ChinookDT" onClick={() => handleNavigation("/")} style={{ height: "48px" }} />
                </Box>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={logoutAndRedirect}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
export default ResponsiveAppBar;