import axios from "../../../../../api/axios"
import { tokenHeader } from "../../../../../api/tokenHeader"

// export const createTask = async ({ task, projectId }) => {
export const createTask = async ({ task, projectId, accessToken }) => {
    // send task along with projectId(from URI / state)
    return await axios.post("/task/create", { task, projectId }, tokenHeader(accessToken))

}

export const getAllTasks = async ({ projectId, accessToken }) => {
    const { data } = await axios.get("/task/all/" + projectId, tokenHeader(accessToken))
    return data
}

export const getOneTask = async () => {
}

export const updateTask = async ({ task, accessToken }) => {
    return await axios.put("/task/update/", { task }, tokenHeader(accessToken))

}

export const deleteTask = async ({ taskId }) => {

}

export const archiveTask = async ({ id, accessToken }) => {
    return await axios.put("/task/archive/" + id, id, tokenHeader(accessToken))
}
