import React, { useContext } from "react";
import { StyleContext } from "./context/styleContext";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/router";
import { AuthContext } from "./context/authContext";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import { useMsal } from "@azure/msal-react";

export default function AppMainArea() {
  const { auth } = React.useContext(AuthContext);
  const { accounts } = useMsal();
  const { sideNavOpen } = useContext(StyleContext);

  const style = accounts.length
    ? sideNavOpen
      ? "main-area-side-nav-opened"
      : "main-area-side-nav-closed"
    : "";
  return (
    // <div className={`container ${style} `} style={{ background: "linear-gradient(135deg, hsla(250, 86%, 97%, 1) 0%, hsla(217, 100%, 93%, 1) 100%)" }}>
    <div className={`container ${style} `} style={{ background: "#F8F9FAFF" }}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div >
  );
}
