import { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { secondAxios } from "../../../api/axios";
import image from "./img/design autom.png";

export default function Section({ section, role, handleDeleteSection }) {
  const imageContainerRef = useRef(null);

  const handleImageClick = () => {
    if (imageContainerRef.current.requestFullscreen) {
      imageContainerRef.current.requestFullscreen();
    } else if (imageContainerRef.current.mozRequestFullScreen) {
      /* Firefox */
      imageContainerRef.current.mozRequestFullScreen();
    } else if (imageContainerRef.current.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      imageContainerRef.current.webkitRequestFullscreen();
    } else if (imageContainerRef.current.msRequestFullscreen) {
      /* IE/Edge */
      imageContainerRef.current.msRequestFullscreen();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        mb: 4,
        p: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        // backgroundColor: "#d7dec8",
        // backgroundColor: "#dbd9d0",
        backgroundColor: "rgba(235,215,188,0.2)",
        width: "98%",
        m: 3,
      }}
    >
      <Box
        sx={{
          flex: 1,
          p: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            fontFamily:
              '"rawline", "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif',
          }}
        >
          {section.header}
        </Typography>
        {role && (
          <Button
            onClick={() => {
              handleDeleteSection(section.id);
            }}
          >
            delete
          </Button>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" mt={2} sx={{ fontSize: 20 }}>
            {section.body}
          </Typography>
          <img
            onClick={handleImageClick}
            ref={imageContainerRef}
            src={`${process.env.REACT_APP_SECOND_API}/solutionsection/image/${section.imgUrl}`}
            width={"30%"}
            alt="section"
            style={{
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.5)",
              },
              borderRadius: 5,
            }}
          />
        </Box>
        <Button sx={{ fontSize: 18 }} onClick={() => {}}>
          Try Now!
        </Button>
      </Box>
      <Box sx={{ flex: 1, p: 2 }}>
        <video width="100%" style={{ borderRadius: 10 }} controls>
          <source
            src={`${process.env.REACT_APP_SECOND_API}/solutionsection/video/${section.vidUrl}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
}
