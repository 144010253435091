import axios from "../../../api/axios"

export const getMyTasks = async ({  accessToken }) => {
    const { data } = await axios.get("/task/myTasks", {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data
}
