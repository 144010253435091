import { Box, Stack, Typography } from "@mui/material";
import { LinearProgressWithLabel } from "../../../components/widgets/LinearProgressWithLabel";


const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const convertDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Format the date
    // const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
    const formattedDate = `${(dateObject.getDate())}-${dateObject.toLocaleString('en-US', { month: 'short' })}-${dateObject.getFullYear()}`;
    return (
        <p>{formattedDate}</p>
    );
}

export const timesheetColumns = [
    {
        id: 'taskColumns',
        header: 'Task',
        columns: [
            {
                accessorKey: "id",
                header: "key",
                size: 50,
                enableEditing: false,
            },
            {
                accessorKey: "task.task",
                header: `Task`,
                size: 300,
                Footer: () => (
                    <Typography color="warning.main" sx={{ pl: 2, fontWeight: "bold" }}>Total</Typography>
                )
            },
            {
                accessorKey: "task.targetDate",
                header: `Due`,
                size: 100,
                Cell: ({ cell }) => (
                    <>
                        {
                            cell.getValue() ?
                                convertDateString(cell.getValue())
                                : ""
                        }
                    </>
                )
            },
        ]
    },
    {
        id: 'timesheetColumns',
        header: 'Timesheets',
        columns: [
            {
                accessorKey: "date",
                header: `Date`,
                size: 60,
                filterVariant: "date"
            },
            {
                accessorKey: "remarks",
                header: `Work Done`,
                size: 300,
            },
            {
                accessorKey: "hours",
                header: `Hours`,
                size: 60,
                enableGrouping: false,
                enableColumnActions: false,
                aggregationFn: 'sum',
                AggregatedCell: ({ cell, table }) => (
                    <>
                        {cell.getValue()}
                    </>
                ),
                Footer: ({ table }) => {
                    const totalHours = table.getRowModel().rows.reduce((total, row) => {
                        return total + parseFloat(row.original.hours || 0);
                    }, 0);

                    return (
                        <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalHours}</Typography>
                        // <Typography>Hours: {totalHours}</Typography>
                    );
                }
            },
            {
                accessorKey: "progress",
                header: `Progress`,
                size: 60,
                enableGrouping: false,
                enableColumnActions: false,
                aggregationFn: 'mean',
                enableSorting: false,
                AggregatedCell: ({ cell, table }) => (
                    <>
                        {Math.round(cell.getValue())}%
                    </>
                ),
                Cell: ({ cell, row }) => (
                    <LinearProgressWithLabel value={cell.getValue()} />
                ),
                Footer: ({ table }) => {
                    const totalProgress = table.getRowModel().rows.reduce((total, row) => {
                        return total + parseFloat(row.original.progress || 0);
                    }, 0);

                    return (
                        <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalProgress}%</Typography>
                        // <Typography>Progress: {totalProgress}%</Typography>
                    );
                }
            },
            {
                accessorKey: "task_member",
                header: `By`,
                size: 60,
            }
        ]
    }
]