const roles = ["owner", "admin", "staff"]

export const columns = [
    {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
        Edit: () => (
            <div></div>
        ),
    },
    {
        accessorKey: 'username',
        header: 'Username',
        size: 80,
    },
    {
        accessorKey: 'email',
        header: 'Email',
        size: 80,
    },
    {
        accessorKey: 'role',
        header: 'Role',
        size: 80,
        editVariant: 'select',
        editSelectOptions: roles,
    },
]