import React, { useContext, useEffect } from 'react'
import ProjectListSideBar from './ProjectListSideBar'
import TaskList from './TaskList'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useGetProjects } from '../ProjectList/projectHooks'
import { AuthContext } from '../../../../context/authContext'
import { useParams } from 'react-router-dom'

const ProjectTasksPage = () => {
    const { projectId } = useParams("projectId")
    const { accessToken } = useContext(AuthContext);
    const {
        data,
        isError: isLoadingItemsError,
        isFetching,
        isLoading,
    } = useGetProjects({ accessToken });
    const [selectedProject, setSelectedProject] = useState({})

    useEffect(() => {
        if (data) {
            let selectedProjectData = data.find(project => project.id === parseInt(projectId));
            setSelectedProject(selectedProjectData)
        }
    }, [projectId, data])

    if (data)
        return (
            <Box display="flex">
                <ProjectListSideBar
                    projects={data}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject} />
                <TaskList
                    // key={projectId}
                    selectedProject={selectedProject} />
            </Box>
        )

}

export default ProjectTasksPage