import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createProject, deleteProject, getAllProjects, updateProject } from "./api";

//CREATE hook (post new project to api)
export function useCreateProject() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({values, accessToken}) => createProject({values, accessToken}),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['projects'] }),
    });
}


//READ hook (get projects from api)
export function useGetProjects({ accessToken }) {
    return useQuery({
        queryKey: ['projects'],
        queryFn: () => getAllProjects({ accessToken }),
        enabled: accessToken !== null
    });
}

//UPDATE hook (put project in api)
export function useUpdateProject() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({values, accessToken}) => updateProject({values, accessToken}),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['projects'] }),
    });
}

//DELETE hook (delete project in api)
export function useDeleteProject() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteProject,
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['projects'] }),
    });
}