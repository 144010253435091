import axios from "../../../../../../api/axios"
import { tokenHeader } from "../../../../../../api/tokenHeader"

export const createComment = async ({ comment, accessToken }) => {
    return await axios.post("/comments/create", comment, tokenHeader(accessToken))
}

export const getComments = async (accessToken, taskId) => {
    const { data } = await axios.get("/comments/all/" + taskId, tokenHeader(accessToken))
    return data
}