import paths from "../../api/paths.api";
import ProjectsPage from "../../pages/staff/ProjectsPage";
import ProjectTasksPage from "../../pages/staff/ProjectsPage/ProjectTasksPage";
// import MyTasks from "../../pages/staff/MyTasks2";
import ComingSoonPage from "../../components/ComingSoonPage";
import MyTasksPage from "../../pages/staff/MyTasksPage";
import MePage from "../../pages/staff/MePage.jsx";

export const authProtectedRoutes = [
    {
        path: paths.mePage,
        // element: <ComingSoonPage />,
        element: <MePage />,
    },
    {
        path: paths.myTasks,
        // element: <ComingSoonPage />,
        element: <MyTasksPage />,
    },
    {
        path: paths.myProjects,
        element: <ProjectsPage />,
    },
    {
        path: paths.singleProject,
        element: <ProjectTasksPage />,
    },
]