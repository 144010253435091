import React, { useContext, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { getAllUsers, editUser, addUser } from "../../../api/usersApi.js"
import { AuthContext } from '../../../context/authContext.js';
import UserRoleCard from './UserRoleCard.js';
import UserTable from './UserTable.jsx';

const CategoryRowFont = {
    fontSize: "1.2rem",
    fontFamily: "'Open Sans','Gill Sans', 'Gill Sans MT', Calibri"
}

const SubCategoryRowFont = {
    fontSize: "1.1rem",
    fontFamily: "Open Sans"
    // fontFamily: "Trebuchet"
}


const ManageStaff = () => {
    const queryClient = useQueryClient()
    const { accessToken } = useContext(AuthContext);
    const initialData = {
        username: "",
        role: "staff",
        id: 0
    }

    const [editForm, setEditForm] = useState(initialData)
    const [formError, setFormError] = useState("")
    const [open, setOpen] = useState(false)
    //fetch data
    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: user_data
    } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers(accessToken),
    })

    const editUserMutation = useMutation({
        mutationFn: (user) => editUser(user, accessToken),
        onSuccess: () => {
            queryClient.invalidateQueries("users")
        }
    })

    const addUserMutation = useMutation({
        mutationFn: (user) => addUser(user, accessToken),
        onSuccess: () => {
            queryClient.invalidateQueries("users")
        }
    })

    const changeHandler = (e) => {
        const { value, name } = e.target
        setEditForm({
            ...editForm,
            [name]: value
        })
    }

    const handleOpen = (e, user) => {
        setEditForm({
            username: user.username,
            role: user.role,
            id: user.id
        })
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        setFormError('')
        setEditForm(initialData)
    };

    const submitHandler = (e) => {
        if (!editForm.role || !editForm.username)
            setFormError("Please fill in all fields")
        else if (!editForm.id)
            setFormError("Failed to load User")
        else {
            editUserMutation.mutate(editForm)
            handleClose()
        }
    }

    if (isSuccess)
        console.log("users: ", user_data)

    if (isLoading)
        return (
            <Box width="100%" height="450px" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Box>
        )

    return (
        <Box sx={{ m: 4, display: "flex" }}>
            <UserTable />
            <UserRoleCard />
        </Box>
    )
}

export default ManageStaff