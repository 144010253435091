import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from "../../context/authContext"
const AuthRoutes = ({ children }) => {
    const { auth } = useContext(AuthContext)
    
    const currentUser = auth.role

    return currentUser ? children : <Navigate to="/" />

    // return children
}

export default AuthRoutes