import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createTimesheet, deleteTimesheet, getAllTimesheets, updateTimesheet } from "./api";
import { useParams } from "react-router-dom";

//CREATE hook (post new timesheet to api)
export function useCreateTimesheet() {
    const queryClient = useQueryClient();

    // Retrieve taskId from the global cache
    const taskId = queryClient.getQueryData(['taskId']);
    const { projectId } = useParams()
    return useMutation({
        mutationFn: (timesheet) => createTimesheet({
            timesheet: timesheet.values,
            accessToken: timesheet.accessToken,
            taskId
        }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['timesheets', taskId] })
            queryClient.invalidateQueries({ queryKey: ['tasks', projectId] })
        },
    });
}

//READ hook (get timesheets from api)
export function useGetTimesheets({ accessToken }) {
    const queryClient = useQueryClient();

    const taskId = queryClient.getQueryData(['taskId']);
    return useQuery({
        queryKey: ['timesheets', taskId],
        queryFn: () => getAllTimesheets({ taskId, accessToken }),
        enabled: accessToken !== null,
        keepPreviousData: true, // Keep the previous data while refetching
    });
}

//UPDATE hook (put timesheet in api)
export function useUpdateTimesheet() {
    const queryClient = useQueryClient();
    const taskId = queryClient.getQueryData(['taskId']);
    const { projectId } = useParams()
    return useMutation({
        mutationFn: (timesheet) => updateTimesheet({
            timesheet: timesheet.values,
            accessToken: timesheet.accessToken,
            taskId
        }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['timesheets', taskId] })
            queryClient.invalidateQueries({ queryKey: ['tasks', projectId] })
        },
    });
}

//DELETE hook (delete timesheet in api)
export function useDeleteTimesheet() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (timesheet) => deleteTimesheet({ timesheetId: timesheet.id, accessToken: timesheet.accessToken }),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['timesheets'] }),
    });
}