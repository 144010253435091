import logo from "../../assets/chinook_white.png";
import "./navbar.css"

import GradButton from "../Button/GradButton/GradButton";
import NavButton from "../Button/NavButton/NavButton";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import paths from "../../api/paths.api";

function HomeNavbar() {

    const loc = useLocation();

    const divider = ".";

    return (
        <div className="nav-container">
            <Link to="/">
                <div className="logo">
                    <img src={logo} alt="ChinookDT logo" width={130} />
                </div>
            </Link>
            <div className="nav-items">
                <NavButton text="Home" route="/" isSelected={loc.pathname == "/" ? true : false} />
                <div className="divider">{divider}</div>
                <NavButton text="EPCM" route={paths.epcmPage} isSelected={loc.pathname == paths.epcmPage ? true : false} />
                <div className="divider">{divider}</div>
                <NavButton text="Software" route={paths.softwarePage} isSelected={loc.pathname == paths.softwarePage ? true : false} />
            </div>
            <div className="nav-end">
                <NavButton text="Contact Us" route={paths.contactPage} isSelected={loc.pathname == paths.contactPage ? true : false} />
                <GradButton text="Login" route={paths.loginPath} />
            </div>
        </div>
    );
}

export default HomeNavbar;