import axios from "axios";
// const BASE_URL = 'https://api.chinookdt.com';
// export const BASE_URL = "http://localhost:8000";
export const BASE_URL = process.env.REACT_APP_API;
export const SECOND_URL = process.env.REACT_APP_SECOND_API;
//
export default axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

export const secondAxios = axios.create({
  withCredentials: true,
  baseURL: SECOND_URL,
});
