import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react'
import { AuthContext } from '../../../context/authContext';
import { useMsal } from '@azure/msal-react';
import { EditableTable } from '../../../components/Tables/EditableTable';
import CommentsDialog from '../ProjectsPage/ProjectTasksPage/TaskList/CommentsDialog';
import TimesheetsDialog from '../ProjectsPage/ProjectTasksPage/TaskList/TimesheetsDialog';
import { useGetMyTasks } from './hooks';
import { minimalMuiTableProps } from '../../../styles/muiProps'
import { Box, Typography } from '@mui/material';
import { taskListColumns } from './columns';
import { EditOnlyTable } from '../../../components/Tables/EditOnlyTable';
import { useUpdateTask } from '../ProjectsPage/ProjectTasksPage/TaskList/hooks';

const MyTasksPage = () => {
  const queryClient = useQueryClient();
  const { accessToken, auth } = useContext(AuthContext);
  const { accounts } = useMsal();
  const username = accounts[0]?.name;
  const currentUser = auth?.role;

  const initialDialogState = {
    comments: false,
    attachment: false,
    timesheet: false
  }

  const [dialogState, setDialogState] = React.useState(initialDialogState)
  const [selectedTask, setSelectedTask] = React.useState()

  const handleButtonClick = (type, row) => {
    setSelectedTask(row.original)
    // Set taskId in the global cache
    queryClient.setQueryData(['taskId'], row.id, { staleTime: Infinity });

    if (type === 'comments') {
      setDialogState(prev => ({ ...prev, comments: true }))
    }
    else if (type === 'attachment') {
      setDialogState(prev => ({ ...prev, attachment: true }))
    }
    else if (type === 'timesheet') {
      setDialogState(prev => ({ ...prev, timesheet: true }))
    }
  }

  const handleDialogClose = () => {
    setDialogState(initialDialogState)
  }


  const hooks = {
    // useCreateItem: (selectedTask) => useCreateTask(selectedTask),
    useGetItems: useGetMyTasks,
    useUpdateItem: useUpdateTask,
    // useDeleteItem: useDeleteTask
  };

  const actions = {
    // validateItem: validateTask
  }

  const additionalProps = {
    ...minimalMuiTableProps,
    enableSorting: true,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
    }
  }

  // const isProjectLead = selectedProject && selectedProject?.projectLeads?.some((lead) => lead === username)
  // const isAdmin = currentUser === "admin" || currentUser === "owner"
  // console.log(isProjectLead, isAdmin)

  return (
    // <Box sx={{ m: 4, width: "100%", pr: 3 }}>
    <Box sx={{ width: "-webkit-fill-available", typography: "body1", overflow: "auto", margin: 4 }}>
      {
        <>
          <Typography variant="h4" color="secondary">My Tasks</Typography>
          {/* <Typography variant="h5" >My Tasks</Typography> */}
          <EditOnlyTable
            columns={taskListColumns(handleButtonClick)}
            name="Task"
            actions={actions}
            hooks={hooks}
            editMode="row"
            enableCreate={false}
            enableEditing={true}
            // enableEditing={true}//or if user is projectLead/admin
            additionalProps={additionalProps}
            initialState={{ sorting: [{ id: "status", desc: true }] }}
          />
        </>
        // : <></>
      }

      {/* :
          <>
            <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} height={60} width={450} />

            <Skeleton animation="wave" variant="rectangular" height={"50vh"} />
          </> */}

      <CommentsDialog open={dialogState.comments} handleClose={handleDialogClose} task={selectedTask} />
      <TimesheetsDialog open={dialogState.timesheet} handleClose={handleDialogClose} task={selectedTask} />
    </Box>
  );
}

export default MyTasksPage