import React from 'react'
import { columns } from './columns'
import { useCreateUser, useDeleteUser, useGetUsers, useUpdateUser } from './hooks';
import { validateUser } from '../../staff/ProjectsPage/utils';
import { minimalMuiTableProps } from '../../../styles/muiProps'
import { EditableTable } from '../../../components/Tables/EditableTable';


const initialData = {
    username: "",
    role: ""
}

const UserTable = () => {

    const hooks = {
        useCreateItem: useCreateUser,
        useGetItems: useGetUsers,
        useUpdateItem: useUpdateUser,
        useDeleteItem: useDeleteUser
    };
    const actions = {
        validateItem: validateUser
    }
    const additionalProps = {
        ...minimalMuiTableProps,
    }

    return (
        <EditableTable
            columns={columns}
            name="User"
            actions={actions}
            hooks={hooks}
            editMode="modal"
            enableCreate={true}
            enableEditing={true}
            additionalProps={additionalProps}
            initialData={initialData}
        />
    )
}

export default UserTable