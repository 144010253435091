import React from "react";

//--------Styles--------
import "./styles/style.scss";
import "./styles/project_cards.scss";
import "./styles/timesheet_style.scss";

import { StyleContextProvider } from "./context/styleContext";
import AppMainArea from "./AppMainArea";

function App() {
  return (
    <div id="wrapper" className="app">
      <StyleContextProvider>
        <AppMainArea />
      </StyleContextProvider>
    </div>
  );
}

export default App;
