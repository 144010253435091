import { React, useState } from 'react'
import { Link } from 'react-router-dom'
import sideImg from '../../images/auth_form_eng.jpg'
import "../../styles/contact.scss"
import { createTheme, ThemeProvider } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Page is Responsive

const theme = createTheme();

theme.typography.h2 = {
    fontSize: '30px',
    fontFamily: "roboto", 
    fontWeight: 300,
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '45px',
    },
};

theme.typography.h6 = {
    fontSize: '15px',
    fontFamily: "roboto", 
    fontWeight: 300,

    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
    },
};

const Contact = () => {
    const [input, setInput] = useState({
        name: "",
        company: "",
        job_title: "",
        country: "",
        message: ""

    })

    const [error, setError] = useState("")

    function changeHandler(event) {
        const { name, value } = event.target
        setInput(prevData => ({
            ...prevData,
            [name]: value
        }))

    }

    const submitHandler = (event) => {
        event.preventDefault()
    }

    // //console.log(input)

    return (
        <Box width="100%" sx={{
            display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center",
        }}>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        maxWidth: "50rem",
                        // width: 600,
                        maxHeight: "100%",
                        height: "fit-content",
                        m: 4,
                        backgroundColor: '#eaecee',
                        borderRadius: 2,
                        padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#4e5fa2',
                        boxShadow: "0px 6px 10px rgba(182, 188, 218, 0.5)"
                    }}
                >
                    <Typography variant="h2" >
                        Get in Touch with our Team
                    </Typography>

                    <Typography variant="h6" color="black" sx={{ mt: 3 }}>
                        Want to learn more about us? We'd love to have a discussion with you!
                    </Typography>
                    {/* <br /> */}

                    <Typography variant="h6" color="black" sx={{ mt: 5 }}>
                        Mail us your queries at
                        <a style={{ textDecoration: "none", color: "#4e5fa2" }} href="mailto:"> info@enerfacprojects.com.</a>
                    </Typography>
                </Box>
            </ThemeProvider>
        </Box>
    )

    // Form to fill queries - not connected to the mail service yet
    return (
        <div className="contact">
            {/* <i className="bi bi-info-square"></i> */}
            <div className="contact-container">
                <div className="contact-text">
                    <h2>Get in Touch with our Team</h2>
                    <p>Have questions about Enerfac? We'd love to let you know about our Exclusive Services!</p>
                    <p>Fill out this form to help us reach you!</p>
                    <p>or Email us at <a>info@enerfacprojects.com</a></p>
                </div>
                <div className="contact-form">
                    <form className="contact--form">
                        <p>{error}</p>
                        <input required type="text"
                            placeholder="Email"
                            name="email"
                            value={input.email}
                            onChange={changeHandler} />
                        <input required type="text"
                            placeholder="Name"
                            name="name"
                            value={input.name}
                            onChange={changeHandler} />
                        <input required type="text"
                            placeholder="Company"
                            name="company"
                            value={input.company}
                            onChange={changeHandler} />
                        <input required type="text"
                            placeholder="Job Title"
                            name="job_title"
                            value={input.job_title}
                            onChange={changeHandler} />
                        <input required type="text"
                            placeholder="Country"
                            name="country"
                            value={input.country}
                            onChange={changeHandler} />
                        <input required type="text"
                            placeholder="Message"
                            name="message"
                            value={input.message}
                            onChange={changeHandler}
                        />
                        <button onClick={submitHandler}>Submit Form</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact