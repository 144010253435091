import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { statusColors } from './utils';
import HorizontalStackedChart from '../../../../components/Chart/HorizontalStackedChart';


const ChartView = ({ taskStatusData, workloadData }) => {
    return (
        <Box m={"16px 0px"} sx={{ display: "flex", gap: "10px" }}>
            {/* <StatusChart data={taskStatusData} title={"Status"} /> */}
            {/* <WorkloadChart data={workloadData} title={"Workload"} /> */}
            <HorizontalStackedChart
                xData={taskStatusData?.statusData}
                yData={taskStatusData?.projects}
                title={"Status"}
                colors={statusColors}
            />
            <HorizontalStackedChart
                xData={workloadData?.statusData}
                yData={workloadData?.users}
                title={"Workload"}
                colors={statusColors}
            />
        </Box>
        // <ChartTemplate chart = {chart} title={"Status"}/>
    )
}

export default ChartView