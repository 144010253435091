import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { indigo } from '@mui/material/colors';


export function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color="primary" />
            </Box>
            <Box sx={{ minWidth: 30 }}>
                {
                    props?.alternateLabel ?
                        <Typography variant="body2" color={indigo[500]}>{props?.alternateLabel}</Typography>
                        :
                        <Typography variant="body2" color={indigo[500]}>{`${Math.round(
                            props.value,
                        )}%`}</Typography>
                }
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};