import PersistLogin from "../components/RoutesHandler/PersistLogin";
import AuthRoutesHandler from "../components/RoutesHandler/AuthRoutesHandler"
import { Navigate, Outlet } from "react-router-dom";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useState, useEffect, useContext } from "react"
import { callApi } from "../services/msalTokenVerify";
import { AuthContext, AuthContextProvider } from "../context/authContext";
// import { callApi } from "./utils"


export const AuthProtected = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);

    return (
        // <AuthContextProvider>
            <div>
                <AuthenticatedTemplate>
                    {/* Content to render if the user is signed in */}
                    <Outlet />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {/* Content to render if the user is not signed in */}
                    <Navigate to="/dev/login" />
                </UnauthenticatedTemplate>
            </div>
        // </AuthContextProvider>
    )
}