import React from 'react'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import LockPersonIcon from '@mui/icons-material/LockPerson';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';

const rolesList = [
    {
        icon: <PeopleIcon />,
        iconColor: "blue",
        label: "Staff",
        description: "Can only view their Assigned Tasks "
    },
    {
        icon: <AdminPanelSettingsIcon />,
        iconColor: "orange",
        label: "Admin",
        description: "Can view tasks - Can View, create and edit projects"
    },
    {
        icon: <LockPersonIcon />,
        iconColor: "red",
        label: "Owner",
        description: "Can view tasks - Can View, create and edit projects - Can register, manage and keep track of Users"
    },

]

const UserRoleCard = () => {
    return (
        <Card sx={{ ml: 4, mt: 5, height: "min-content" }}>
            <CardContent>
                <Typography>User Roles:</Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        rolesList.map((role, i) => (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: role.iconColor }}>
                                        {role.icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={role.label} secondary={role.description} />
                            </ListItem>
                        ))
                    }
                </List>
            </CardContent>
        </Card>
    )
}

export default UserRoleCard