import "./epcm.css"
import Line from "../../../assets/epcm-line.svg";
import Pentagon from "../../../assets/Pentagon.svg"

function EPCMPage() {
    return(
        <div className="epcm-container">
            <div class="epcm-hero-section">
                <div className="epcm-hero">
                    <div className="epcm-title">EPCM</div>
                    <div className="epcm-desc">Engineering, Procurement and Construction Management </div>
                    <img src={ Line } className="line"/>
                </div>
                <div className="epcm-hero-img">
                    <img src={ Pentagon } className="pentagon" />
                </div>
            </div>
        </div>
    );
}

export default EPCMPage;