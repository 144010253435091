import axios from "./axios";
import { tokenHeader } from "./tokenHeader";

const getAllUsersRoute = "/dev/all"
const getAllUsernamesRoute = "/dev/allUsernames"
const AddUserRoute = "/dev/create" //+id
const EditUserRoute = "/dev/edit" //+id

export const getAllUsers = async (accessToken) => {
    const res = await axios.get(getAllUsersRoute, tokenHeader(accessToken))
    return res.data
}

export const getAllUsernames = async (accessToken) => {
    const res = await axios.get(getAllUsernamesRoute, tokenHeader(accessToken))
    return res.data
}

export const addUser = async (user, accessToken) => {
    return await axios.put(AddUserRoute, user, tokenHeader(accessToken))
}

export const editUser = async (user, accessToken) => {
    return await axios.put(EditUserRoute, user, tokenHeader(accessToken))
}

