import React from 'react'


import TableChartIcon from '@mui/icons-material/TableChart';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Tab, Tabs } from '@mui/material';

const ChangeViewTabs = ({ value, handleChange }) => {
    return (
        <Tabs value={value} onChange={handleChange} centered>
            <Tab icon={<TableChartIcon />} />
            <Tab icon={<LeaderboardIcon />} />
        </Tabs>
    )
}

export default ChangeViewTabs