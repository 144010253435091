import { useContext, useEffect, useMemo, useState } from 'react';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    createRow,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionBar from '../ActionBar';
import { AuthContext } from '../../context/authContext';
import { useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';


export const EditOnlyTable = ({ name, columns, actions, hooks, editMode, enableEditing, additionalProps, initialState, enableCreate }) => {
    const { accessToken } = useContext(AuthContext);
    const [validationErrors, setValidationErrors] = useState({});

    //call READ hook
    const {
        data: fetchedItems = [],
        isError: isLoadingItemsError,
        isFetching: isFetchingItems,
        isLoading: isLoadingItems,
    } = hooks.useGetItems({ accessToken });
    //call UPDATE hook
    const { mutateAsync: updateItem, isPending: isUpdatingItem } =
        hooks.useUpdateItem();

    //UPDATE action
    const handleSaveItem = async ({ values, row, table }) => {
        // const newValidationErrors = actions.validateItem(values);
        // if (Object.values(newValidationErrors).some((error) => error)) {
        //     setValidationErrors(newValidationErrors);
        //     return;
        // }
        // setValidationErrors({});
        // console.log({ values, row })
        await updateItem({ values: { ...values, id: row.id }, accessToken });
        table.setEditingRow(null); //exit editing mode
    };


    const table = useMaterialReactTable({
        columns: columns,
        data: fetchedItems,
        editDisplayMode: editMode, //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: enableEditing,
        getRowId: (row) => row.id,
        muiSkeletonProps: {
            animation: 'wave',
        },
        muiLinearProgressProps: {
            color: 'secondary',
        },
        muiCircularProgressProps: {
            color: 'secondary',
        },
        muiToolbarAlertBannerProps: isLoadingItemsError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveItem,
        positionActionsColumn: "last",
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h5">Edit {name}</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents} {/* or render custom edit components here */}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        positionGlobalFilter: "left",
        ...(additionalProps ? { ...additionalProps } : {}),
        state: {
            isLoading: isLoadingItems,
            isSaving: isUpdatingItem,
            showAlertBanner: isLoadingItemsError,
            showProgressBars: isFetchingItems,

        },
        initialState: { columnVisibility: { id: false }, showGlobalFilter: true, ...initialState }
    });

    // return <MaterialReactTable table={table} />;
    return (
        <Box sx={{ mt: 3 }}>
            {/* <ActionBar table={table} initialData={initialData} name={name} enableCreate={enableCreate} /> */}
            <MaterialReactTable table={table} />
        </Box>
    )
};
