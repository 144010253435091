import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#4043ac",
        },
        secondary: {
            main: '#2c387e',
        },
    },
    typography: {
        fontSize: 12,
        fontFamily:"Open Sans"
    }
});
