import { Box, Card, CardContent, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import styled from '@emotion/styled';
import { useDrawingArea } from '@mui/x-charts/hooks';
import SkeletonBox from '../../../../components/Loading/SkeletonBox';

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 16,
}));

function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
}

const TaskCountCard = ({ data }) => {
    const completedTasks = data?.completedTasks
    const tasksAssigned = data?.tasksAssigned
    const percentageCompleted = data?.completedTasks / data?.tasksAssigned * 100 || 0
    return (
        <Paper elevation={2} sx={{ width: "300px", height: "160px", display: "flex" }}>
            <PieChart
                series={[
                    {
                        data: [
                            { id: 0, value: completedTasks, label: 'series A', color: "#2E1164" },
                            { id: 1, value: tasksAssigned - completedTasks, label: 'series A', color: "#BCA1EF" },
                        ],
                        innerRadius: 30,
                        outerRadius: 50,
                        paddingAngle: 2,
                        cornerRadius: 3,
                    },
                ]}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'left' },
                        hidden: true,
                    },
                }}
                margin={{
                    left: 60,
                }}
                width={100}
            // height={100}
            >
                <PieCenterLabel>{percentageCompleted}%</PieCenterLabel>
            </PieChart>
            <Stack sx={{ width: "160px", p: 3 }} >
                <Typography variant="h6" color="#6A5FACFF">{completedTasks} of {tasksAssigned} </Typography>
                <Typography color="#323743FF" fontWeight="500">Tasks completed this month</Typography>
                <br />
                <Typography color="#6A5FACFF" >{100 - percentageCompleted || 0}% more</Typography>
            </Stack>
        </Paper >
    )
}

export default TaskCountCard