import React, { useContext } from "react";

import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { StyleContext } from "../../../context/styleContext";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function SideNavHeader() {
  const theme = useTheme();

  const { sideNavOpen, setSideNavOpen } = useContext(StyleContext);

  return (
    <DrawerHeader>
      {!sideNavOpen ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setSideNavOpen(true);
          }}
          edge="start"
          sx={{
            ...(sideNavOpen && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <span>
          <IconButton
            onClick={() => {
              setSideNavOpen(false);
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </span>
      )}
    </DrawerHeader>
  );
}
