import "./GradButton.css"

function GradButton({ className, text, route }) {
    return (
        <a href={route}>
            <div className={`btn ${className}`}>
                {text}
            </div>
        </a>
    );
}

export default GradButton;