import React, { createContext, useState } from "react";

const StyleContext = createContext({
  sideNavOpen: false,
  setSideNavOpen: (open) => {},
});

function StyleContextProvider({ children }) {
  const [sideNavOpen, setSideNav] = useState();

  function setSideNavOpen(open) {
    setSideNav(open);
    console.log({ open });
  }

  return (
    <StyleContext.Provider value={{ sideNavOpen, setSideNavOpen }}>
      {children}
    </StyleContext.Provider>
  );
}

export { StyleContextProvider, StyleContext };
