import { MaterialReactTable } from 'material-react-table'
import React from 'react'
import { tableDesignProps } from '../../../styles/muiProps'

const TableView = ({ columns, data }) => {
    return (
        <MaterialReactTable
            enableGrouping={true}
            columns={columns}
            enablePagination={false}
            enableColumnDragging={false}
            defaultColumn={{
                minSize: 20,
            }}
            // globalFilterFn= 'contains'
            data={data || []}
            {...tableDesignProps}
            initialState={{
                columnVisibility: { id: false },
                sorting: [{ id: "project.projectName", desc: true }],
                grouping: ['project.projectName']
            }}
        />
    )
}

export default TableView