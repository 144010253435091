import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import ResponsiveAppBar from './AppBar';
import { useMsal } from '@azure/msal-react';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: "none",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: "50px",

  '@media (min-width: 600px)': {
    minHeight: '50px',  // Override height for screens over 600px
  },

}));

const NavWrapper = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ResponsiveAppBar
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      {accounts?.length ?
        // <SideNav /> :

        <Box sx={{ display: 'flex' }}>
          <SideDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <DrawerHeader />
            {children}
          </Box>
        </Box> :
        <></>
      }
    </>
  );
};
export default NavWrapper;
