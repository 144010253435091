import { useContext } from "react"
import axios from "../../../api/axios"
import { tokenHeader } from "../../../api/tokenHeader"

export const createUser = async ({ values, accessToken }) => {
    return await axios.post("/dev/create", values, tokenHeader(accessToken))
}

export const getAllUsers = async ({ accessToken }) => {
    const { data } = await axios.get("/dev/all", tokenHeader(accessToken))
    return data
}

export const updateUser = async ({ values, accessToken }) => {
    return await axios.put("/dev/update", values, tokenHeader(accessToken))
}

export const deleteUser = async ({ userId }) => {

}