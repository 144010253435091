import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IconButton } from '@mui/material';
import { AuthContext } from '../../../context/authContext';
import { getAllUsers } from '../ManageStaff/api';
import { useQuery } from '@tanstack/react-query';

export default function FilterOptions({ filter, setFilter, open, setOpen }) {

    const { accessToken } = React.useContext(AuthContext);

    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: user_data
    } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers({ accessToken }),
    })

    console.log(user_data)

    const handleChange = (event) => {
        setFilter({ userId: event.target.value, projectId: "" });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div style={{ width: "100%", paddingRight: "2rem", display: 'flex', justifyContent: "flex-end" }}>
            {/* <IconButton aria-label="delete" onClick={handleOpen}>
                <FilterAltIcon />
            </IconButton> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="open-select-label" size='small'>User</InputLabel>
                <Select
                    labelId="open-select-label"
                    id="open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={filter?.userId}
                    size='small'
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        user_data?.length ?
                            user_data?.map(user => (
                                <MenuItem value={user?.id}>
                                    {user?.username}
                                </MenuItem>
                            ))
                            : <></>
                    }
                </Select>
            </FormControl>
        </div>
    );
}
