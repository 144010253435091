import { Card, CardContent, Paper, Typography } from '@mui/material'
import React from 'react'

const TaskPriorityCard = () => {
    return (
        <Paper elevation={2} sx={{ width: "300px", height: "160px" }}>

        </Paper>
    )
}

export default TaskPriorityCard