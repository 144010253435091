function getDaysInMonth(year, month) {
    // Month is 0-based (0 = January, 1 = February, etc.)
    return new Date(year, month + 1, 0).getDate();
}

export function createDayArray(year, month) {
    const daysInMonth = getDaysInMonth(year, month);
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

export function getCurrentMonthName() {
    const date = new Date();
    const monthIndex = date.getMonth(); // Get the current month index (0-based)
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[monthIndex];
  }