import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const NavList = ({ open, items }) => {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path)
    }

    return (
        <List >
            {
                items.map(item => (
                    <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                justifyContent: "center",
                                px: 2.5,
                                flexDirection: "column",
                                mb: 1
                                // minHeight: 48,
                                // justifyContent: open ? 'initial' : 'center',
                                // px: 2.5,
                            }}
                            onClick={() => {
                                handleNavigation(item.path);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    size: "30px",
                                    // mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            {/* <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} /> */}
                            {/* <ListItemText primary={item.label} fontSize={"11px"} sx={{ fontSize: "11px" }} /> */}
                            {/* <ListItemText secondary={item.label} fontSize={"11px"} sx={{ fontSize: "11px" }} /> */}
                            <Typography sx={{ fontSize: "11px" }} >{item.label}</Typography>
                        </ListItemButton>
                    </ListItem>
                )
                )
            }
        </List>
    )
}

export default NavList