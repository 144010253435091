import { Navigate } from "react-router-dom";

import Contact from "../../pages/public/Contact";

//--------Staff-------
import StaffLogin from "../../pages/staff/auth/StaffLogin";
import ContactPage from "../../pages/public/ContactPage/Contact";
import EPCMPage from "../../pages/public/EPCMPage/EPCM";
import SoftwarePage from "../../pages/public/SoftwarePage/Software";
import LandingPage from "../../pages/public/LandingPage/LandingPage";

export const mainRoutes = [
  // {
  //   index: true,
  //   element: <Navigate to="/home" />,
  // },
  {
    path: "/home",
    element: <LandingPage />
  },
  {
    path: "/home/contact",
    element: <ContactPage />,
  },
  {
    path: "/home/epcm",
    element: <EPCMPage />,
  },
  {
    path: "/home/software",
    element: <SoftwarePage />,
  },
  
];
