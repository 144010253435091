import { styled, lighten, darken } from "@mui/system";

export const tabProps = {
  textColor: "secondary",
  indicatorColor: "secondary",
  sx: {
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    "& .MuiTabs-scroller": {
      padding: "1px",
      marginTop: "5px",
      backgroundColor: "#fff",
    },
    "& .MuiTab-root.MuiButtonBase-root ": {
      minHeight: "20px",
      padding: "8px 10px",
      minWidth: "70px",
    },
    "& .right ": {
      display: "inline-block",
      float: "right",
      marginLeft: "200px",
      fontWeight: "bold",

      span: {
        fontWeight: "400",
        cursor: "pointer",
      },
    },
  },
};

export const smallInputField = {
  style: { fontSize: "0.875rem", height: "24px" },
};
export const SCMTabProps = {
  textColor: "secondary",
  indicatorColor: "secondary",
  sx: {
    width: "100%",
    overflow: "hidden",
    "& .MuiTabs-scroller": {
      padding: "1px",
      marginTop: "5px",
    },
    "& .MuiTab-root.MuiButtonBase-root ": {
      minHeight: "20px",
      padding: "8px 5px",
      minWidth: "70px",
    },
    "& .right ": {
      display: "inline-block",
      float: "right",
      marginLeft: "200px",
      fontWeight: "bold",

      span: {
        fontWeight: "400",
        cursor: "pointer",
      },
    },
  },
};

export const GridStyle = {
  "&.MuiGrid-item": { p: "0px 0px 0px 0px" },
};

export const designTabBoxProps = {
  sx: {
    mb: 0.5,
    p: 1,
    pt: 1,
    pb: 1,
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
  },
};

export const tabScrollerProps = {
  sx: {
    borderBottom: 1,
    borderColor: "divider",
    "& .MuiTabs-root": {
      minHeight: "20px",
    },
    "& .MuiTab-root.MuiButtonBase-root ": {
      minHeight: "20px",
    },
  },
};

export const drawingSelectorStyle = {
  sx: {
    padding: "0px 5px",
    mb: 2,
    alignItems: "center",
    justifyContent: "center",
  },
};

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));
export const GroupSubHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.95)
      : darken(theme.palette.primary.main, 0.9),
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

export const smallerHeadCellProps = {
  muiTableHeadCellProps: {
    sx: {
      borderRight: "1px solid rgba(224,224,224,1)",
      borderTop: "1px solid rgba(224,224,224,1)",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        marginBottom: "0.4px",
      },
      // fontSize: "0.7rem",
      verticalAlign: "middle",
      height: "64.5px",
      padding: "2px",
      backgroundColor: "hsla(250, 86%, 97%, 1)",
      fontSize: "16px",
      textAlign: "center",
      // pt: 2,
    },
  },
};

export const compactTableProps = {
  muiTableHeadCellProps: {
    sx: {
      borderRight: "1px solid rgba(224,224,224,1)",
      borderTop: "1px solid rgba(224,224,224,1)",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        marginBottom: "0.4px",
      },
      fontSize: "0.7rem",
      verticalAlign: "middle",
      height: "95px",
      padding: "2px",
    },
  },
  muiTableProps: {
    size: "small",
    sx: {
      "& .MuiInputBase-root": {
        fontSize: "0.7rem",
        borderRadius: 0,
      },
      "& .MuiButtonBase-root": {
        fontSize: "0.7rem",
      },
      "& .MuiTableCell-root": {
        fontSize: "0.7rem",
      },
    },
  },
  muiTableBodyCellProps: {
    sx: {
      textAlign: "center",
      borderRight: "1px solid #ddd",
      padding: "0rem",
      height: "33.1px",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      "& .css-1llu0od": {
        padding: "0rem 0.5rem",
      },
    },
  },
  initialState: { density: "compact" },
};

export const greyTable = {
  muiTableBodyCellProps: {
    sx: {
      textAlign: "center",
      borderRight: "1px solid #ddd",
      padding: "0rem",
      height: "33.1px",
      backgroundColor: "#F5F5F5"
    },
  }
}

export const unborderedTableProps = {
  muiTableHeadCellProps: {
    sx: {
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        marginBottom: "0.4px",
      },
      fontSize: "0.7rem",
      verticalAlign: "middle",
      height: "95px",
      padding: "2px",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      padding: "0rem",
      height: "33.1px",
    },
  },
};

export const normalTableProps = {
  muiTableHeadCellProps: {
    sx: {
      borderRight: "1px solid rgba(224,224,224,1)",
      borderTop: "1px solid rgba(224,224,224,1)",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        // marginBottom: "0.4px"
      },
      fontSize: "0.8rem",
      verticalAlign: "middle",
    },
  },
  muiTableProps: {
    size: "small",
    sx: {
      "& .MuiInputBase-root": {
        fontSize: "0.8rem",
        borderRadius: 0,
      },
      "& .MuiButtonBase-root": {
        fontSize: "0.8rem",
      },
      "& .MuiTableCell-root": {
        fontSize: "0.8rem",
      },
    },
  },
  muiTableBodyCellProps: {
    sx: {
      height: "32.4px",
    },
  },
  // initialState: { density: 'compact' }
};

export const mcstableProps = {
  muiTableHeadCellProps: {
    sx: {
      borderRight: "1px solid rgba(224,224,224,1)",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        // marginBottom: "0.4px"
      },
      fontSize: "0.7rem",
      verticalAlign: "middle",
    },
  },
  muiTableProps: {
    size: "small",
    sx: {
      "& .MuiInputBase-root": {
        fontSize: "0.7rem",
        borderRadius: 0,
      },
      "& .MuiButtonBase-root": {
        fontSize: "0.7rem",
      },
      "& .MuiTableCell-root": {
        fontSize: "0.7rem",
      },
    },
  },
  muiTableBodyCellProps: {
    sx: {
      padding: "0.5rem",
      height: "32.4px",
    },
  },
  // initialState: { density: 'compact' }
};

export const zeroElevationTableProps = {
  elevation: 0, //change the mui box shadow
  //customize paper styles
  sx: {
    borderRadius: "0",
    // border: '1px dashed #e0e0e0',
  },
};

export const tableWithBorderedCells = {
  muiTableBodyCellProps: {
    sx: {
      height: "32.4px",
      borderRight: "1px solid rgba(224,224,224,0.5)",
    },
  },
};
export const verticalTabStyle = {
  sx: {
    borderRight: 1,
    // mt: 2,
    minWidth: "150px",
    width: "150px",
    borderColor: "divider",
    marginBottom: "10px",

    "& .MuiTab-root.MuiButtonBase-root": {
      marginTop: "10px",
    },
    "& .MuiTab-root.Mui-selected": {
      bgcolor: "#e9ecf6",
    },
    textAlign: "left",
  },
};

export const fullPageBoxStyle = {
  sx: {
    width: "100%",
    minHeight: "85vh",
    overflow: "auto",
    // bgcolor: "whitesmoke",
    bgcolor: "#fff",
    borderRadius: "15px",
    boxSizing: "border-box",
    display: "flex",
    textAlign: "left",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
  },
};

export const linksUnstyled = {
  // textDecoration: 'none',
  color: "black",
};

export const bottomNavigationBoxStyle = {
  display: "flex",
  flexDirection: "row",
  width: "inherit",
  flexWrap: "wrap",
  alignItems: "end",
  pb: 2,
  pl: 2,
};


// Dashboard props
export const dashboardContainerProps = {
  sx: {
    borderRadius: "6px",
    backgroundColor: "#F8F8F8",
    width: "80vw",
    minHeight: "80vh",
    padding: 2
  }
}

export const dashboardNumberCardBoxProps = {
  sx: {
    borderRadius: "6px",
    backgroundColor: "#F8F8F8",
    display: "flex",
    // flexDirection:"column"
  }
}

export const chartContainerProps = {
  width: 400,
  height: "fit-content",
  borderRadius: "6px",
  backgroundColor: "#FFFFFF",
  boxShadow: " 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  pl: 4,
  // display: "flex",
  // alignItems: "center",
  mt: 2, ml: 4
}

export const tableDesignProps = {
  muiTableProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
    },
  },
  muiTableBodyProps: {
    sx: {
      backgroundColor: "white",
    },

  },
  muiTableHeadCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
      backgroundColor: "hsla(250, 86%, 97%, 1)",
      // fontSize: "16px",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 0,
      // backgroundColor: "hsla(250, 86%, 97%, 1)",
      // fontSize: "16px",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
    },
  },
  muiTableFooterCellProps: {
    sx: {
      // border: "1px solid rgba(224,224,224,0.5)",
      padding: "5px"
    },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      backgroundColor: "rgba(0,0,0,0)",
      "& .MuiAlert-root": {
        backgroundColor: "rgb(219, 233, 255) ",
        // background: "linear-gradient(135deg, rgb(243, 241, 254) 0%, rgb(219, 233, 255) 100%)"
      },
    },
  },
  // muiTopToolbarProps: {
  //   sx: {
  //     // backgroundColor: "rgba(0,0,0,0)",
  //     mb: 1
  //   },
  // }
}

export const minimalMuiTableProps = {
  enableColumnActions: false,
  enableColumnFilters: true,
  columnFilterDisplayMode: 'popover',
  // enablePagination: false,
  enableSorting: false,
  // enableBottomToolbar: false,
  // enableTopToolbar: false,
  enableToolbarInternalActions: false,
  muiTableBodyRowProps: { hover: false },
  muiTableProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
    },
  },
  muiTableBodyProps: {
    sx: {
      backgroundColor: "white",
    },

  },
  muiTableHeadCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
      backgroundColor: "hsla(250, 86%, 97%, 1)",
      // fontSize: "16px",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 0,
      backgroundColor: "hsla(250, 86%, 97%, 1)",
      // fontSize: "16px",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
    },
  },
  muiTableFooterCellProps: {
    sx: {
      // border: "1px solid rgba(224,224,224,0.5)",
      padding: "5px"
    },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      backgroundColor: "rgba(0,0,0,0)"
    },
  },
  muiTopToolbarProps: {
    sx: {
      backgroundColor: "rgba(0,0,0,0)",
      mb: 1
    },
  }
}

export const styledTableProps = {
  enableToolbarInternalActions: false,

}

export const greyedOutFields = {
  fontSize: "0.7rem",
  height: "18px",
  backgroundColor: "#e5e8f2",
  color: "black"
}