import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { getHoursDashboard, getProgressDashboard } from './api';
import { AuthContext } from '../../../context/authContext';
import { useQuery } from '@tanstack/react-query';
import { LineChart } from '@mui/x-charts';
import ChartTemplate from '../../../components/Chart/ChartTemplate';
import FilterOptions from './FilterOptions';
import { getStatusDashboard } from '../AllTasksPage/api';
import HorizontalStackedChart from '../../../components/Chart/HorizontalStackedChart';
import { statusColors } from '../AllTasksPage/ChartView/utils';
import { getCurrentMonthName } from '../../../utils/dateFunctions';


const CustomItemTooltipContent = (props, data, type) => {
    const { itemData, series } = props;
    // console.log({ itemData, series }, props, series.valueFormatter);
    const item = data[itemData.dataIndex];
    const projects = item?.projects?.length ? item?.projects?.join(", ") : ""
    const users = item?.users?.length ? item?.users?.join(", ") : ""
    const isHoursGraph = type === "hours"
    // const text = isHoursGraph ? 
    return (
        <Paper sx={{ padding: 3, fontSize: "0.875rem", maxWidth: "400px" }}>
            {/* <p>🔹Hours: {item?.totalHours || item?.totalProgress}</p> */}
            <p>🔹{isHoursGraph ? `Hours:  ${item?.totalHours ?? ""}` : `Progress: ${item?.totalProgress ?? ""}%`}</p>
            <p>🔹Projects: {projects}</p>
            <p>🔹Users: {users}</p>
        </Paper>
    );
};

const DashboardPage = () => {

    const { accessToken } = useContext(AuthContext);
    const [filter, setFilter] = React.useState({
        userId: "",
        projectId: ""
    });
    const [open, setOpen] = React.useState(false);

    const { isSuccess, isLoading, isError, data: hoursDashboardData } = useQuery({
        queryKey: ['hours_dashboard', filter.userId],
        queryFn: () => getHoursDashboard({ accessToken, userId: filter.userId || undefined }),
    })

    const { data: statusDasboardData } = useQuery({
        queryKey: ['status_dashboard', filter.userId],
        queryFn: () => getStatusDashboard({ accessToken, userId: filter.userId || undefined }),
    })

    const { data: progressDasboardData } = useQuery({
        queryKey: ['progress_dashboard', filter.userId],
        queryFn: () => getProgressDashboard({ accessToken, userId: filter.userId || undefined }),
    })

    const hoursChart = <LineChart
        xAxis={[{
            data: hoursDashboardData?.map(item => item.day) || [],
            label: `${getCurrentMonthName()}`, max: 31,
        }]}
        series={[
            {
                data: hoursDashboardData?.map(item => item?.totalHours) || [],
                // valueFormatter: (hours, { dataIndex }) => {
                //     const projects = hoursDashboardData[dataIndex]?.projects?.length ? hoursDashboardData[dataIndex].projects.join(", ") : "";
                //     const users = hoursDashboardData[dataIndex]?.users?.length ? hoursDashboardData[dataIndex].users.join(", ") : "";
                //     return `${hours} hours\n🔹 Projects: ${projects} 🔹 Users: ${users}`;
                // },
                area: true,
            },

        ]}
        yAxis={[{ label: 'Hours' }]}
        margin={{ bottom: 60 }}
        slotProps={{
            legend: {
                labelStyle: {
                    fontSize: "12px"
                },
            },
        }}
        tooltip={{
            trigger: "item", itemContent: (props) => CustomItemTooltipContent(props, hoursDashboardData, "hours")
        }}

    // slots={{
    //     axisContent: (props) => CustomItemTooltipContent(props, hoursDashboardData),
    //     itemContent: (props) => CustomItemTooltipContent(props, hoursDashboardData)
    // }}
    />

    const progressChart = <LineChart
        xAxis={[{ data: progressDasboardData?.map(item => item.day) || [], label: `${getCurrentMonthName()} `, max: 31, }]}
        series={[
            {
                data: progressDasboardData?.map(item => item.totalProgress) || [],
                // valueFormatter: (progress, { dataIndex }) => {
                //     const projects = progressDasboardData[dataIndex]?.projects?.length ? progressDasboardData[dataIndex].projects.join(", ") : "";
                //     return `${progress}% progress\n🔹 Projects: ${projects} `;
                // },
                area: true,
            },
        ]}
        yAxis={[{ label: 'Progress' }]}
        margin={{ bottom: 60 }}
        slotProps={{
            legend: {
                labelStyle: {
                    fontSize: "12px"
                },
            },
        }}
        tooltip={{
            trigger: "item", itemContent: (props) => CustomItemTooltipContent(props, progressDasboardData, "progress")
        }}
    />

    return (
        <Box sx={{ m: 3, width: "100%", pr: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", pr: 2, pb: 2 }}>
                <Typography variant="h4" fontSize={"1.5rem"} color="secondary">Dashboard</Typography>
                <Box>
                    <FilterOptions
                        filter={filter}
                        setFilter={setFilter}
                        open={open}
                        setOpen={setOpen}
                    />
                </Box>
            </Box>
            {/* <br /> */}
            {
                hoursDashboardData ?
                    <>
                        <Grid container spacing={2}>
                            <Grid item>
                                <HorizontalStackedChart
                                    xData={statusDasboardData?.statusData || []}
                                    yData={statusDasboardData?.projects || []}
                                    title={"Status"}
                                    colors={statusColors}
                                    // width="468px"
                                    height="630px"
                                />
                            </Grid>
                            <Grid item>
                                <Box>
                                    <ChartTemplate chart={hoursChart} title={"Hours Spent"}
                                        // width="468px"
                                        height="300px"
                                    />
                                    <br />
                                    <ChartTemplate chart={progressChart} title={"Progress"}
                                        // width="468px"
                                        height="300px" />
                                </Box>
                            </Grid>
                            {/* <Grid item>
                            </Grid> */}

                        </Grid>
                    </>
                    :
                    <>
                        Loading
                    </>
            }
        </Box>
    )
}

export default DashboardPage