import "./NavButton.css"
import { Link } from "react-router-dom";

const NavButton = ({ className, text, route, isSelected }) => {
    return(
        <Link to={route}>
            <div className={isSelected ? `nav-btn-selected ${className}` : `nav-btn ${className}`}>
                {text}
            </div>
        </Link>
    );
}

export default NavButton;