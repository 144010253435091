import React, { useContext } from 'react'
import { getAllTimesheets } from './api';
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from '../../../context/authContext';
import { timesheetColumns } from './columns';
import { Box, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { tableDesignProps } from '../../../styles/muiProps';
import { LocalizationProvider } from '@mui/x-date-pickers';

const AllTimesheetsPage = () => {
    const { accessToken } = useContext(AuthContext);

    const { isSuccess, isLoading, isError, data: allTimesheets } = useQuery({
        queryKey: ['timesheets_all'],
        queryFn: () => getAllTimesheets({ accessToken }),
    })
    if (isSuccess) {
        console.log({ allTimesheets })
    }

    return (
        <Box sx={{ "display": "flex", alignItems: "start" }}>
            <Box sx={{ m: 4, width: "100%", pr: 3 }}>
                <Typography variant="h4" color="secondary" pb={2}>All Timesheets</Typography>
                <MaterialReactTable
                    columns={timesheetColumns}
                    enableGrouping
                    enableColumnDragging={false}
                    defaultColumn={{
                        minSize: 20, //allow columns to get smaller than default
                        // maxSize: 9001, //allow columns to get larger than default
                        // size: 260, //make columns wider by default
                    }}
                    data={allTimesheets || []}
                    {...tableDesignProps}
                    initialState={{
                        columnVisibility: { id: false },
                        sorting: [{ id: "date", desc: true }],
                        grouping: ['task.task']
                    }}
                />
            </Box>
        </Box>
    )
}

export default AllTimesheetsPage