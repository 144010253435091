import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import paths from "../../../api/paths.api";
import { Badge, SvgIcon } from "@mui/material";


export const ownerList = {
  array: [
    {
      label: "Members",
      icon: <PeopleAltIcon />,
      path: paths.allstaff,
    },
  ],
};

export const staffList = {
  array: [
    {
      label: "Projects",
      icon: <FolderCopyIcon />,
      path: paths.myProjects,
    },
    {
      label: "My Tasks",
      icon: <AssignmentIcon color="action" />,
      path: paths.myTasks,
    },
  ],
};

export const adminList = {
  array: [
    {
      label: "Dashboard",
      icon: <Badge color="warning" variant="dot" overlap="circular" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
        <AssignmentIcon />
      </Badge>,
      path: paths.dashboard,
    },
    {
      label: "All Tasks",
      icon: <Badge color="warning" variant="dot" overlap="circular" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
        <AssignmentIcon />
      </Badge>,
      path: paths.allTasks,
    },
    {
      label: "All Timesheets",
      icon: <Badge color="warning" variant="dot" overlap="circular" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
        <PunchClockIcon />
      </Badge>
      ,
      path: paths.allTimesheets,
    }
  ],
};


