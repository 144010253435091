import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SectionModal = ({ open, handleClose, handleSave }) => {
  const [header, setHeader] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);

  const handleVideoUpload = (event) => {
    setVideo(event.target.files[0]);
  };
  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    let formData = new FormData();
    console.log(header);
    // formData.append("header", header);
    // formData.append("body", paragraph);
    // formData.append("video", video);
    handleSave({ header, video, paragraph, image });
    setHeader("");
    setParagraph("");
    setVideo(null);
    setImage(null);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create Section
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Header"
            variant="outlined"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Paragraph"
            variant="outlined"
            multiline
            rows={4}
            value={paragraph}
            onChange={(e) => setParagraph(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Upload Video
            <input
              type="file"
              hidden
              accept="video/*"
              onChange={handleVideoUpload}
            />
          </Button>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Upload Chart
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SectionModal;
