import React from 'react'
import "../../styles/error.scss"
const Error = (props) => {
    return (
        <div className="ErrorBox">
            <div className="mainbox">
                <div className="err">4</div>
                <i className="far bi-question-circle fa-spin"></i>

                {props.error === "UserNotAdmin" ?
                    <div>
                        <div className="err2">3</div>
                        <div className="msg">Sorry! You are not authorized to access this page
                            <p>Let's redirect you to <a href="/">home</a></p>
                        </div>
                    </div>
                    : 
                    <div>
                        <div className="err2">4</div>
                        {/* <div className="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place? */}
                        {/* <div className="msg">Sorry! This page doesn't exist or has been moved' */}
                        <div className="msg">Sorry! We faced an issue handling your request. Please try again'
                        <p>Let's go <a href="/">home</a> and try from there.</p>
                    </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Error