import React from "react";
import { Box, Typography } from "@mui/material";

const WideImage = ({ src, alt }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src={src}
        alt={alt}
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: 400,
          m: 3,
          borderRadius: 2,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "20px",
          transition: "background-color 0.3s, transform 1s",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            transform: "scale(1.05)",
          },
        }}
      >
        <Typography variant="h1" component="h1">
          Automate Your Work
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ maxWidth: "600px", fontSize: 25 }}
        >
          Design automation and data management can significantly streamline
          your workflow, reducing manual tasks and increasing efficiency.
        </Typography>
      </Box>
    </Box>
  );
};

export default WideImage;
