import { Badge, Box, Button, Chip, IconButton, MenuItem } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { CircularProgressWithLabel } from "../../../components/widgets/CircularProgressWithLabel";

const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};


const convertDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Format the date
    // const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
    const formattedDate = `${(dateObject.getDate())}-${dateObject.toLocaleString('en-US', { month: 'short' })}-${dateObject.getFullYear()}`;
    return (
        <p>{formattedDate}</p>
    );
}

const statusColorMap = {
    'Not Started': 'default',
    'Progress': 'primary',
    'Hold': 'warning',
    'Completed': 'success',
    'Delayed': 'error',
};

export const taskListColumns = (handleButtonClick) => [
    {
        accessorKey: "id",
        header: `ID`,
        enableEditing: false
    },
    {
        accessorKey: "project.projectName",
        header: `Project`,
        enableEditing: false
    },
    {
        accessorKey: "task",
        header: `Task`,
        enableEditing: false,
        size: 280,
    },
    {
        accessorKey: "task_members",
        header: `Responsible`,
        size: 100,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ cell, row }) => {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {
                        cell.getValue() ?
                            cell.getValue().map((user, index) => (
                                <Chip key={index} label={user} size="small" />
                            ))
                            :
                            ""
                    }
                </Box>
            )
        },
    },
    {
        accessorKey: "priority",
        header: 'Priority',
        enableColumnFilter: false,
        enableEditing: false,
        size: 90,
        muiEditTextFieldProps: () => ({
            children: ["High", "Medium", "Low"].map((func) => (
                <MenuItem key={func} value={func}>
                    {func}
                </MenuItem>
            )),
            select: true,
        }),
    },
    {
        accessorKey: "startDate",
        header: `Start`,
        size: 50,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
            <>
                {
                    cell.getValue() ?
                        <div style={{ whiteSpace: 'wrap' }}>
                            {convertDateString(cell.getValue())}
                        </div>
                        : ""
                }
            </>
        ),
        muiEditTextFieldProps: ({ cell }) => ({
            type: "date",
            size: "small"
        }),
    },
    {
        accessorKey: "targetDate",
        header: `Due`,
        size: 50,
        enableColumnFilter: false,
        enableEditing: false,
        Cell: ({ cell }) => (
            <>
                {
                    cell.getValue() ?
                        <div style={{ whiteSpace: 'wrap' }}>
                            {convertDateString(cell.getValue())}
                        </div>
                        : ""
                }
            </>
        ),
        muiEditTextFieldProps: ({ cell }) => ({
            type: "date",
        }),
    },
    {
        accessorKey: "hours",
        header: 'Hours',
        enableEditing: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 60,
    },
    {
        accessorKey: "progress",
        header: 'Progress',
        enableEditing: false,
        enableColumnFilter: false,
        size: 60,
        enableSorting: false,
        Cell: ({ cell, row }) => (
            <CircularProgressWithLabel value={cell.getValue()} />
        )
    },
    {
        accessorKey: "status",
        header: `Status`,
        enableColumnFilter: false,
        size: 80,
        muiEditTextFieldProps: () => ({
            children: ["Not Started", "Progress", "Hold", "Completed", "Delayed"].map((func) => (
                <MenuItem key={func} value={func}>
                    {func}
                </MenuItem>
            )),
            select: true,
        }),
        Cell: ({ cell }) => (
            <>
                {
                    cell.getValue() ?
                        <Chip
                            label={cell.getValue()}
                            size="small"
                            color={statusColorMap[cell.getValue()] || 'default'}
                        /> :
                        <></>
                }
            </>
        )
    },
    {
        accessorKey: "comments",
        header: `Comments`,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 50,
        Header: () => (<ChatIcon />),
        Cell: ({ row }) => (
            <IconButton
                sx={{ padding: "0" }}
                onClick={() => handleButtonClick('comments', row)}
            >
                <ChatIcon />
            </IconButton>
        ),
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    },
    {
        accessorKey: "attachment",
        header: `Attachment`,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 20,
        Header: () => <AttachFileIcon />,
        Cell: ({ row }) => (
            <IconButton
                sx={{ padding: "0" }}
                onClick={() => handleButtonClick('attachment', row)}
            >
                <AttachFileIcon />
            </IconButton>
        ),
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    },
    {
        accessorKey: "timesheet",
        header: `Timesheet`,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 30,
        Header: () => <PostAddIcon />,
        Cell: ({ row }) => (
            <IconButton
                sx={{ padding: "0" }}
                onClick={() => handleButtonClick('timesheet', row)}
            // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
            >
                <PostAddIcon />
            </IconButton>
        ),
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    },
];
