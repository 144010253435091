import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from "../../context/authContext"
import Error from "../../pages/public/Error"

const AdminRoutesHandler = ({ children }) => {
  const { auth } = useContext(AuthContext)
  const role = auth.role

  return (
    (role === "admin" || role === "owner") ? children : <Error error={"UserNotAdmin"} />
    // children 
  )
}

export default AdminRoutesHandler