import "./contact.css";

function ContactPage() {


    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return(
        <div class="contact-container">
            <div className="contact-head">
                <div className="contact-title">Get in touch to start your tranformations.</div>
                <div className="contact-title-desc">Contact our team and transform your projects with our engineering solutions.</div>
            </div>
            <form className="contact-form" 
                  onSubmit={ handleSubmit }>
                <input className="name form-item"
                       placeholder="Your Name"/>

                <input className="email form-item"
                       placeholder="E-mail address"/>

                <input className="phone form-item"
                       placeholder="Phone no."/>

                <textarea className="msg form-item"
                       placeholder="Tell us about your project..."/>

                <input type="submit" 
                       className="submit form-item"/>
            </form>
        </div>
    );
}

export default ContactPage;