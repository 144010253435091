const validateRequired = (value) => !!value?.length;
export const categories = ["Software", "Engineering", "Website", "Marketing"]
export const sidebarWidth = "200px"
export function validateProject(project) {
    return {
        projectName: !validateRequired(project.projectName)
            ? 'Project Name is Required'
            : '',
    };
}

export function validateTask(task) {
    return {
        task: !validateRequired(task.task)
            ? 'Task Name is Required'
            : '',
    };
}

export function validateTimesheet(timesheet) {
    return {
        timesheet: !validateRequired(timesheet.remarks)
            ? 'Details is Required'
            : '',
    };
}

export function validateUser(user) {
    // console.log("validating", user)
    return {
        user: !validateRequired(user.username)
            ? 'User Name is Required'
            : '',
    };
}