import axios from "../../../api/axios"
import { tokenHeader } from "../../../api/tokenHeader"

export const getHoursDashboard = async ({ accessToken, userId, projectId }) => {
    const { data } = await axios.get("task/hoursDashboard/" + userId, tokenHeader(accessToken))
    return data
}

export const getProgressDashboard = async ({ accessToken, userId, projectId }) => {
    const { data } = await axios.get("task/progressDashboard/" + userId, tokenHeader(accessToken))
    return data
}